import { jsx as e } from "react/jsx-runtime";
import { useRef as p, useEffect as g } from "react";
import { useIsTogether as v } from "../../hooks/index.js";
import M from "../../hooks/useMyId.js";
function T({
  messages: t,
  MessageRow: i,
  MessageAvatar: n,
  MessageBody: s,
  ConnectPrompt: f,
  WelcomeMessage: c
}) {
  const l = M(), m = v(), r = p(null);
  return g(() => {
    r.current && r.current.scrollIntoView({ behavior: "smooth" });
  }, [t]), /* @__PURE__ */ e("div", { className: "rt-messageContainer", children: m ? t.length > 0 ? t.map(({ id: u, senderId: o, sentAt: a, message: h }, d) => /* @__PURE__ */ e(
    "div",
    {
      ref: d === t.length - 1 ? r : void 0,
      children: /* @__PURE__ */ e(
        i,
        {
          senderId: o,
          message: h,
          sentAt: a,
          isMe: o === l,
          MessageAvatar: n,
          MessageBody: s
        }
      )
    },
    u
  )) : /* @__PURE__ */ e(c, {}) : /* @__PURE__ */ e(f, {}) });
}
export {
  T as default
};
