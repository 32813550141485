import { useRef as l, useEffect as c } from "react";
import "@croquet/react";
import "../models/ReactTogetherModel.js";
import "unique-names-generator";
import m from "./useStateTogetherWithPerUserValues.js";
function P(r, d = {}) {
  const o = l(null), [v, s, a] = m(r, !1);
  c(() => {
    const e = o.current;
    if (!e)
      return;
    const t = (i) => {
      const u = i.rtProcessedBy;
      u === void 0 ? (s(!0), i.rtProcessedBy = r) : u !== r && s(!1);
    }, n = () => s(!1);
    return e.addEventListener("mouseover", t), e.addEventListener("mouseleave", n), () => {
      e.removeEventListener("mouseover", t), e.removeEventListener("mouseleave", n);
    };
  }, [s, r]);
  const f = Object.entries(a).filter(([, e]) => e).map(([e]) => e);
  return [o, f, v];
}
export {
  P as default
};
