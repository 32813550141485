import { jsx as s, jsxs as r } from "react/jsx-runtime";
function n(e) {
  return new Date(e).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}
function l({
  isMe: e,
  senderId: a,
  message: t,
  sentAt: i,
  formatTime: d = n
}) {
  return /* @__PURE__ */ s("div", { className: `rt-message-divider ${e ? "isMe" : ""}`, children: /* @__PURE__ */ r("div", { className: `rt-message-border ${e ? "isMe" : ""}`, children: [
    /* @__PURE__ */ s("span", { className: "rt-messageLabel", children: a }),
    /* @__PURE__ */ r("div", { children: [
      /* @__PURE__ */ s("span", { className: "rt-message-text", children: t }),
      " ",
      /* @__PURE__ */ s("span", { className: "rt-messageLabel", children: i ? d(i) : "???" })
    ] })
  ] }) });
}
export {
  l as default
};
