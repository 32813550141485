import { jsxs as u, jsx as t } from "react/jsx-runtime";
import { useIsTogether as x } from "../../hooks/index.js";
function g({
  messages: e,
  chatName: r,
  sendMessage: i,
  minimizeChat: o,
  ChatHeader: s,
  MessageList: a,
  MessageRow: n,
  MessageAvatar: m,
  MessageBody: h,
  ChatInput: c,
  ConnectPrompt: d,
  WelcomeMessage: f
}) {
  const p = x();
  return /* @__PURE__ */ u("div", { className: "rt-chatContainer", children: [
    /* @__PURE__ */ t(s, { chatName: r, minimizeChat: () => o() }),
    /* @__PURE__ */ t(
      a,
      {
        messages: e,
        MessageRow: n,
        MessageBody: h,
        MessageAvatar: m,
        ConnectPrompt: d,
        WelcomeMessage: f
      }
    ),
    p && /* @__PURE__ */ t(c, { sendMessage: i })
  ] });
}
export {
  g as default
};
