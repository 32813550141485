import { useCroquetContext as I } from "@croquet/react";
import j from "object-hash";
import { useState as v, useEffect as w, useCallback as k } from "react";
import C from "./getNewValue.js";
import L from "./useMyId.js";
const P = Object.freeze({});
function x(e) {
  return Object.fromEntries(e.entries());
}
function U(e, c) {
  const n = x(e), V = j(n);
  return c && delete n[c], {
    allValues: n,
    allValuesHash: V
  };
}
function W({
  prevLocalValue: e,
  initialValue: c,
  sessionValue: n,
  resetOnConnect: V,
  overwriteSessionValue: d
}) {
  return V ? c : e === void 0 ? n ?? c : n === void 0 ? e ?? c : d ? e : n;
}
function J(e, c, n = {}) {
  const {
    resetOnDisconnect: V = !1,
    resetOnConnect: d = !1,
    keepValues: h = !1,
    overwriteSessionValue: b = !1,
    omitMyValue: p = !1
  } = n, [u] = v(c), { session: H, view: t, model: a } = I(), l = L(), [S, g] = v(() => {
    if (!t || !a || l === null)
      return {
        localValue: u,
        allValues: P,
        allValuesHash: null
      };
    const o = a.statePerUser.get(e) ?? /* @__PURE__ */ new Map([[l, u]]), { allValues: r, allValuesHash: s } = U(
      o,
      p ? l : void 0
    );
    return {
      localValue: o.get(l) ?? u,
      allValues: r,
      allValuesHash: s
    };
  });
  w(() => {
    if (t && a && l) {
      const o = a.statePerUserConfig.get(e);
      (!o || o.keepValues !== h) && t.publish(a.id, "configureStatePerUser", {
        rtKey: e,
        options: {
          // intentionally not passing other options to save bandwidth.
          // These values do not need to be synchronized
          // across users, and are only used locally to determine the behavior of the setter
          keepValues: h
        }
      });
    }
  }, [t, a, e, V, d, h, l]), w(() => {
    if (!H || !t || !a || l === null) {
      g((s) => ({
        localValue: V ? u : s.localValue,
        allValues: P,
        allValuesHash: null
      }));
      return;
    }
    (() => {
      g((s) => {
        const i = new Map(a.statePerUser.get(e)), m = i.get(l), f = W({
          prevLocalValue: s.localValue,
          initialValue: u,
          sessionValue: m,
          resetOnConnect: d,
          overwriteSessionValue: b
        });
        f !== m && (t.publish(a.id, "setStatePerUser", {
          rtKey: e,
          userId: l,
          value: f
        }), i.set(l, f));
        const { allValues: T, allValuesHash: E } = U(
          i,
          p ? l : void 0
        );
        return { localValue: f, allValues: T, allValuesHash: E };
      });
    })();
    const r = () => {
      g((s) => {
        const i = a.statePerUser.get(e) ?? /* @__PURE__ */ new Map(), { allValues: m, allValuesHash: f } = U(i, p ? l : void 0);
        return s.allValuesHash === f ? s : {
          localValue: i.get(l) ?? u,
          allValues: m,
          allValuesHash: f
        };
      });
    };
    return t.subscribe(
      e,
      { event: "updated", handling: "oncePerFrame" },
      r
    ), () => t.unsubscribe(e, "updated", r);
  }, [
    e,
    H,
    t,
    a,
    u,
    l,
    V,
    d,
    h,
    b,
    p
  ]);
  const M = k(
    (o) => {
      if (!t || !a || l === null)
        g((r) => {
          const s = C(r.localValue, o);
          return r.localValue === s ? r : {
            localValue: s,
            allValues: P,
            allValuesHash: null
          };
        });
      else {
        let s = a.statePerUser.get(e).get(l);
        s === void 0 && (console.warn(
          `[useStateTogetherWithPerUserValues:setter] prevLocalValue is undefined.Using initialValue: ${u}`
        ), s = u);
        const i = C(s, o);
        t.publish(a.id, "setStatePerUser", {
          rtKey: e,
          userId: l,
          value: i
        });
      }
    },
    [e, t, a, u, l]
  ), { localValue: A, allValues: O } = S;
  return [A, M, O];
}
export {
  J as default
};
