import { jsxs as c, jsx as a } from "react/jsx-runtime";
function i({
  message: e,
  senderId: r,
  sentAt: o,
  isMe: s,
  MessageAvatar: t,
  MessageBody: m
}) {
  return /* @__PURE__ */ c("div", { className: `rt-message-row ${s ? "isMe" : ""}`, children: [
    /* @__PURE__ */ a(t, { isMe: s, senderId: r }),
    /* @__PURE__ */ a(
      m,
      {
        isMe: s,
        senderId: r,
        sentAt: o,
        message: e
      }
    )
  ] });
}
export {
  i as default
};
