import { default as x } from "./useChat.js";
import { default as h } from "./useConnectedUsers.js";
import { default as S } from "./useCreateRandomSession.js";
import { default as T } from "./useFunctionTogether.js";
import { default as C } from "./useHoveringUsers.js";
import { useSessionParams as t, useIsJoined as s } from "@croquet/react";
import { useMemo as a } from "react";
import { default as J } from "./useLeaveSession.js";
import { default as L } from "./useMyId.js";
import { default as P } from "./useStateTogether.js";
import { default as y } from "./useStateTogetherWithPerUserValues.js";
import { getJoinUrl as u } from "../utils/urls.js";
function d() {
  const { name: e, password: o } = t(), r = n();
  return a(() => !r || !e || !o ? null : u(new URL(window.location.href), e, o).toString(), [e, o, r]);
}
const n = s;
export {
  x as useChat,
  h as useConnectedUsers,
  S as useCreateRandomSession,
  T as useFunctionTogether,
  C as useHoveringUsers,
  n as useIsTogether,
  d as useJoinUrl,
  J as useLeaveSession,
  L as useMyId,
  P as useStateTogether,
  y as useStateTogetherWithPerUserValues
};
