import { useCroquetContext as h, usePublish as l } from "@croquet/react";
import { useState as g, useEffect as b, useCallback as C } from "react";
import { getChat as r } from "../models/ReactTogetherModel.js";
import p from "./useMyId.js";
const u = [];
function I(s) {
  const a = p(), { view: t, model: n } = h(), [m, c] = g(() => {
    if (!n)
      return u;
    const e = r(n, s);
    return e ? e.messages : u;
  });
  b(() => {
    if (!n || !t) {
      c(u);
      return;
    }
    r(n, s) || t.publish(n.id, "createChat", { rtKey: s });
    const o = () => {
      const f = r(n, s);
      if (!f) {
        console.warn(`Chat ${s} not found`);
        return;
      }
      c([...f.messages]);
    };
    return o(), t.subscribe(s, "messageSent", o), () => t.unsubscribe(s, "messageSent", o);
  }, [n, t, s]);
  const i = l((e) => [s, "sendMessage", e]), d = C(
    (e) => {
      if (e.trim() === "")
        return;
      const o = Date.now();
      if (a === null) {
        console.warn("Cannot send message outside of a session");
        return;
      }
      i({ message: e, senderId: a, sentAt: o });
    },
    [i, a]
  );
  return { messages: m, sendMessage: d };
}
export {
  I as default
};
