var g = Object.defineProperty;
var n = (t, e, s) => e in t ? g(t, e, { enumerable: !0, configurable: !0, writable: !0, value: s }) : t[e] = s;
var a = (t, e, s) => (n(t, typeof e != "symbol" ? e + "" : e, s), s);
import { ReactModel as r } from "@croquet/react";
class M extends r {
  constructor() {
    super(...arguments);
    a(this, "rtKey");
    a(this, "messages");
    a(this, "nextMessageId");
  }
  init({ rtKey: s }) {
    super.init({}), this.rtKey = s, this.messages = [], this.nextMessageId = 0, this.subscribe(s, "sendMessage", this.handleNewMessage);
  }
  handleNewMessage({ message: s, senderId: h, sentAt: d }) {
    const i = {
      message: s,
      senderId: h,
      sentAt: d,
      id: this.nextMessageId++
    };
    this.messages.push(i), this.publish(this.rtKey, "messageSent", i);
  }
}
M.register("ChatModel");
export {
  M as default
};
