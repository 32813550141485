import { jsxs as c, Fragment as f, jsx as d } from "react/jsx-runtime";
import u from "color-hash";
import "@croquet/react";
import "react";
import "../models/ReactTogetherModel.js";
import g from "../hooks/useMyId.js";
import "unique-names-generator";
import h from "../hooks/useHoveringUsers.js";
import "object-hash";
const x = new u();
function R({
  rtKey: o,
  children: n,
  className: m,
  highlightMyself: r = !1
}) {
  const [l, e, p] = h(o), a = g();
  let i = {};
  const t = r ? e : e.filter((s) => s !== a);
  return (t.length > 0 || r && p) && (i = {
    outline: `2px solid ${x.hex(t[0] ?? o)}`,
    animation: "clippath 3s linear infinite",
    borderRadius: "10px"
  }), /* @__PURE__ */ c(f, { children: [
    /* @__PURE__ */ d("div", { ref: l, style: i, className: m, children: n }),
    !1
  ] });
}
export {
  R as default
};
