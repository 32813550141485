import { jsx as r } from "react/jsx-runtime";
import { useState as B } from "react";
import { useIsTogether as H } from "../../hooks/index.js";
import '../../assets/Chat.css';/* empty css                   */import L from "./ChatExpanded.js";
import P from "./ChatHeader.js";
import R from "./ChatInput.js";
import W from "./ChatMinimized.js";
import E from "./ConnectPrompt.js";
import T from "./MessageAvatar.js";
import j from "./MessageBody.js";
import G from "./MessageList.js";
import S from "./MessageRow.js";
import b from "./WelcomeMessage.js";
import k from "../../hooks/useChat.js";
function p({
  rtKey: t,
  chatName: i = "Group Chat",
  components: a,
  hideWhenDisconnected: s = !1
}) {
  const { messages: M, sendMessage: g } = k(t), d = H(), C = (a == null ? void 0 : a.ChatMinimized) ?? W, h = (a == null ? void 0 : a.ChatExpanded) ?? L, f = (a == null ? void 0 : a.ChatHeader) ?? P, u = (a == null ? void 0 : a.MessageList) ?? G, l = (a == null ? void 0 : a.MessageRow) ?? S, $ = (a == null ? void 0 : a.MessageAvatar) ?? T, z = (a == null ? void 0 : a.MessageBody) ?? j, x = (a == null ? void 0 : a.ChatInput) ?? R, I = (a == null ? void 0 : a.ConnectPrompt) ?? E, v = (a == null ? void 0 : a.WelcomeMessage) ?? b, [w, y] = B(!1), e = () => {
    y((A) => !A);
  };
  return (!s || d) && /* @__PURE__ */ r("div", { className: "rt-chat", children: w ? /* @__PURE__ */ r(
    C,
    {
      chatName: i,
      expandChat: () => e()
    }
  ) : /* @__PURE__ */ r(
    h,
    {
      messages: M,
      chatName: i,
      ChatHeader: f,
      MessageRow: l,
      MessageBody: z,
      ChatInput: x,
      MessageList: u,
      MessageAvatar: $,
      ConnectPrompt: I,
      WelcomeMessage: v,
      sendMessage: g,
      minimizeChat: () => e()
    }
  ) });
}
export {
  p as default
};
