import { jsx as o } from "react/jsx-runtime";
import { useMemo as e } from "react";
import { getUserColor as l } from "../../utils/colors.js";
function u({ isMe: a, senderId: r }) {
  const i = e(() => l(r), [r]), s = e(() => {
    if (!r)
      return "";
    const t = r.trim();
    return `${t[0]}${t[t.length - 1]}`.toUpperCase();
  }, [r]);
  return /* @__PURE__ */ o(
    "div",
    {
      className: `rt-avatar ${a ? "isMe" : ""}`,
      style: { backgroundColor: i },
      children: /* @__PURE__ */ o("label", { className: "rt-initials", children: s })
    }
  );
}
export {
  u as default
};
